import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Watch } from 'vue-property-decorator';
import { Role } from '../../shared/models/dto/roles';
import { FilterOperator } from '../../shared/models/shared/filter-operator';
import { ODataFilterItem, ODataFilterObject } from '../../shared/models/shared/odata-filter-object';
import { IRestResponseDto } from '../../shared/models/shared/rest-response-dto';

@Component({
    components: {
        vDataTableFilter: require('@/views/_components/v-data-table-filter/v-data-table-filter.vue').default,
        editCreateRoleDialog: require('@/views/roles/edit-create-role-dialog.vue').default,
        columnSelectDialog: require('@/views/_components/column-select-dialog/column-select-dialog.vue').default
    }
})
export default class RoleListComponent extends AuthComponentBase {
    currentRouteName: string = null;
    loading: boolean = true;
    showDialog: boolean = false;
    showColumnDialog: boolean = false;
    showFilter: boolean = false;
    availableColumnsAreSet: boolean = false;
    id: string = '';

    options = {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['name']
    };
    totalCount: number = 0;
    items: Role[] = [];

    headers = [];
    availableHeaders = [];

    returnHeaders() {
        return [
            { text: this.t('RoleName'), value: 'name', filterOperator: FilterOperator.contains, inputType: 'string' },
            { text: this.t('IsSystemDefault'), value: 'isSystemDefault', width: '160px', filterOperator: FilterOperator.eq, inputType: 'boolean' },
            { text: this.t('Actions'), value: 'actions', sortable: false, width: '160px' }
        ];
    }

    @Watch('options', { deep: true, immediate: false })
    onPaginationChanged() {
        this.getItems();
    }

    @Watch('$route', { immediate: true, deep: true })
    onUrlChange(newVal: any) {
        // if the route contains a param, this watcher makes sure the detailpage is opened
        this.currentRouteName = !this.currentRouteName ? newVal.name : this.currentRouteName;

        if (newVal && newVal.params && newVal.params.id) {
             this.edit(newVal.params.id);
        } else {
            this.showDialog = false;
        }
    }

    created() {
        this.headers = this.returnHeaders();
        this.availableHeaders = this.returnHeaders();
    }

    mounted() {
        this.$appHub.$on('update-role-list', this.getItems);
    }

    beforeDestroy() {
        //clean SignalR event
        this.$appHub.$off('update-role-list', this.getItems);
    }

    getItems(filterItems?: ODataFilterItem[]) { // when called from v-data-table-filter, it provides the items
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage }: any = this.options;
        const queryObj: ODataFilterObject = {
            filter: filterItems && filterItems.length ? filterItems : [],
            pageIndex: page - 1,
            pageSize: itemsPerPage,
            $count: true
        };

        if (sortBy.length > 0 && sortBy[0]) {
            queryObj.sortBy = sortBy + ((sortDesc && sortDesc.length > 0 && sortDesc[0]) ? ' desc' : '');
        }

        const query = this.queryBuilder(queryObj);

        this.authService.get<IRestResponseDto<Role[]>>(`/api/roles${query}`, false).then((response) => {
            this.items = <Role[]><unknown>response.content;
            this.totalCount = response.count;
            this.setAvailableColumns();
            this.loading = false;
        }).catch((error) => {
            this.totalCount = 0;
            this.loading = false;
        });
    }

    deleteItem(id: string) {
        this.swalConfirm(this.t('AreYouSureToDelete'), true, this.t('Delete')).then((result) => {
            if (result.value) {
                this.authService.delete(`/api/roles/${id}`).then((response) => {
                    if (!response.isError) {
                        this.swalToast(2000, 'success', this.t('ChangesSaved'));
                        this.getItems();
                    } else {
                        var errorText = "";
                        response.errors.forEach((error: { name: string; }) => {
                            errorText += this.t(error.name) + '<br>';
                        });
                        this.swalAlert('error', errorText);
                    }
                });
            }
        });
    }

    edit(id: string) {
        this.id = id ? id : '';

        if (id) {       
            this.$router.push({ name: this.currentRouteName, params: { id: this.id.toString() } });
        }

        this.showDialog = true;
    }

    completed(reload: boolean) {
        this.$router.push({ name: this.currentRouteName });
        this.showDialog = false;
        if (reload) {
            this.getItems();
        }
    }

    setAvailableColumns() {
        if (this.items.length) {
            for (const [key, value] of Object.entries(this.items[0])) {
                if (!this.availableHeaders.some(e => e.value === key)) { 
                    this.availableHeaders.push({ 
                        text: key !== 'id' ? this.t(key) : key, 
                        value: key, 
                        sortable: true, 
                        width: 'auto', 
                        filterOperator: this.returnFilterOperator(this.items[0], key),
                        inputType: this.returnPropType(this.items[0], key), 
                    });
                }
            }
        }

        this.availableColumnsAreSet = true;
    }

    columnSelectCompleted(headers: any) {
        if (headers != null) { 
            this.headers = [...headers];       
        }

        this.showColumnDialog = false;
    }
}